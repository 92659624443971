import React from 'react'
import PropTypes from 'prop-types'
import { LightboxProvider, useLightboxState } from './LightboxProvider'
import { MenuProvider, useMenuState } from './MenuProvider'
import { LocaleProvider, useLocaleState } from './LocaleProvider'
import { PortalProvider, usePortalState } from './PortalProvider'
import { LogoProvider, useLogoState } from './LogoProvider'
import { WishlistProvider, useWishlistState } from './WishlistProvider'
import { TwitchProvider, useTwitchState } from './TwitchProvider'

function ContextProvider({ children }) {
    return (
        <LocaleProvider>
            <TwitchProvider>
                <PortalProvider>
                    <MenuProvider>
                        <WishlistProvider>
                            <LogoProvider>
                                <LightboxProvider>{children}</LightboxProvider>
                            </LogoProvider>
                        </WishlistProvider>
                    </MenuProvider>
                </PortalProvider>
            </TwitchProvider>
        </LocaleProvider>
    )
}

export {
    ContextProvider,
    useMenuState,
    useLightboxState,
    useLocaleState,
    usePortalState,
    useLogoState,
    useWishlistState,
    useTwitchState,
}

ContextProvider.propTypes = {
    children: PropTypes.object.isRequired,
}
