import React from 'react'
import PropTypes from 'prop-types'

import Link from '@components/Link'
import Icon from '@components/Icon'

import cx from 'classnames'

export default function LinkWithIcon({
    color,
    to,
    external = false,
    text,
    icon,
    clickHandler,
    className,
    iconSrc,
}) {
    return to ? (
        <Link
            {...{
                to,
                external,
                clickHandler,
                className: 'hello',
            }}
        >
            {text && <span>{text}</span>}
            {(icon || iconSrc) && (
                <div className="">
                    <Icon
                        name={icon}
                        src={iconSrc}
                        className={cx('', className)}
                    />
                </div>
            )}
        </Link>
    ) : (
        <span
            {...{
                onClick: (e) => clickHandler(e),
                className: '',
            }}
        >
            {text && <span>{text}</span>}
            {(icon || iconSrc) && (
                <div className="">
                    <Icon
                        name={icon}
                        src={iconSrc}
                        className={cx('', className)}
                    />
                </div>
            )}
        </span>
    )
}

LinkWithIcon.propTypes = {
    color: PropTypes.string,
    to: PropTypes.string,
    external: PropTypes.bool,
    text: PropTypes.string,
    icon: PropTypes.string,
    clickHandler: PropTypes.func,
    className: PropTypes.string,
    iconSrc: PropTypes.string,
}

LinkWithIcon.defaultProps = {
    external: false,
}
