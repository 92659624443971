import React from "react";

export default function DashedLine({
  length,
  width,
  color,
  dasharray,
  className,
  isVertical = false,
  svgWidth,
  svgPosition,
}) {
  return (
    <div className={className}>
      {isVertical && svgPosition ? (
        <svg id="chart" width={svgWidth} height="225">
          <line x1={svgPosition.x1} y1="20" x2="20" y2="130"></line>
          <g fill="none" stroke={color} strokeWidth={width}>
            <line
              x1={svgPosition.x1}
              y1={svgPosition.y1}
              x2={svgPosition.x2}
              y2={length}
              strokeDasharray={dasharray}
            ></line>
          </g>
        </svg>
      ) : (
        <svg height="20" width={svgWidth}>
          <g fill="none" stroke={color} strokeWidth={width}>
            <path strokeDasharray={dasharray} d={`M5 10 l${length} 0`} />
          </g>
          Sorry, your browser does not support inline SVG.
        </svg>
      )}
    </div>
  );
}
