import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

export default function Title({
  className,
  children,
  font = "display",
  color = "black",
  size,
  type = 1,
}) {
  switch (type) {
    case 1:
      return (
        <h1
          {...{
            className: cx(
              `uppercase tracking-wide text-5xl lg:text-6xl xl:text-7xl text-${color}`,
              {
                "font-display ru-countach-font": font === "display",
                "font-body": font === "body",
              },
              className
            ),
          }}
        >
          {children}
        </h1>
      );
    case 2:
      return (
        <h2
          {...{
            className: cx(
              `uppercase tracking-wide text-4xl lg:text-5xl xl:text-6xl text-${color}`,
              {
                "font-display ru-countach-font": font === "display",
                "font-body": font === "body",
              },
              className
            ),
          }}
        >
          {children}
        </h2>
      );
    case 3:
      return (
        <h3
          {...{
            className: cx(
              `uppercase tracking-wide text-3xl lg:text-4xl xl:text-5xl text-${color}`,
              {
                "font-display ru-countach-font": font === "display",
                "font-body": font === "body",
              },
              className
            ),
          }}
        >
          {children}
        </h3>
      );
    case 4:
      return (
        <h4
          {...{
            className: cx(
              `uppercase tracking-wide text-2xl lg:text-3xl xl:text-4xl text-${color}`,
              {
                "text-xl": size === "2xl",
                "font-display ru-countach-font": font === "display",
                "font-body": font === "body",
              },
              className
            ),
          }}
        >
          {children}
        </h4>
      );
    case 5:
      return (
        <h5
          {...{
            className: cx(
              `uppercase tracking-wide text-xl lg:text-2xl xl:text-3xl text-${color}`,
              {
                "font-display ru-countach-font": font === "display",
                "font-body": font === "body",
              },
              className
            ),
          }}
        >
          {children}
        </h5>
      );
    case 6:
      return (
        <h6
          {...{
            className: cx(
              `uppercase tracking-wide text-lg lg:text-xl xl:text-2xl text-${color}`,
              {
                "font-display ru-countach-font": font === "display",
                "font-body": font === "body",
              },
              className
            ),
          }}
        >
          {children}
        </h6>
      );

    default:
      return (
        <h1
          {...{
            className: cx(
              `uppercase tracking-wide text-5xl lg:text-6xl xl:text-7xl text-${color}`,
              {
                "font-display ru-countach-font": font === "display",
                "font-body": font === "body",
              },
              className
            ),
          }}
        >
          {children}
        </h1>
      );
  }
}

Title.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
  color: PropTypes.string,
  font: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.number,
};

Title.defaultProps = {
  color: "black",
  size: "2xl",
  type: 1,
  font: "display",
};
