import React, { createContext, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const TwitchContext = createContext()

/**
 * TwitchProvider
 *
 * @param {mixed} children
 */
const TwitchProvider = ({ children }) => {
    const [online, setOnline] = useState(false)

    useEffect(() => {
        const checkTwitch = async () => {
            await fetch('/api/twitch-status', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(async (response) => {
                const _response = await response.json()
                if (_response?.data?.length > 0 && !online) {
                    setOnline(true)
                } else if (_response?.data?.length === 0 && online) {
                    setOnline(false)
                }
            })
        }

        if (!online) checkTwitch()

        return () => checkTwitch()
    }, [])

    useEffect(() => {
        const interval = setInterval(async () => {
            await fetch('/api/twitch-status', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then(async (response) => {
                const _response = await response.json()
                if (_response?.data?.length > 0 && !online) {
                    setOnline(true)
                } else if (_response?.data?.length === 0 && online) {
                    setOnline(false)
                }
            })
        }, 30000)

        return () => {
            if (interval) clearInterval(interval)
        }
    }, [online])

    return (
        <TwitchContext.Provider value={{ online, setOnline }}>
            {children}
        </TwitchContext.Provider>
    )
}

/**
 * useTwitchState
 */
const useTwitchState = () => {
    const context = useContext(TwitchContext)

    if (context === undefined)
        throw new Error('useTwitchState must be used within a TwitchProvider')

    return context
}

export { TwitchProvider, useTwitchState }

TwitchProvider.propTypes = {
    children: PropTypes.element.isRequired,
}
