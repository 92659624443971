import React, { createContext, useContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';

const WishlistContext = createContext();

const WishlistProvider = ({ children }) => {
    const [show, setShow] = useState(false);

    return (
        <WishlistContext.Provider value={{ show, setShow }}>
            {children}
        </WishlistContext.Provider>
    )
}

const useWishlistState = () => {
    const context = useContext(WishlistContext);

    if (context === undefined) throw new Error(`useWishlistState must be used within a PortalProvider`);

    return context;
}

export { WishlistProvider, useWishlistState }

WishlistProvider.propTypes = {
    children: PropTypes.element.isRequired
}