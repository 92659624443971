import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const MenuContext = createContext()

/**
 * MenuProvider
 * 
 * @param {mixed} children
 */
const MenuProvider = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState(false)

    return (
        <MenuContext.Provider value={{ menuOpen, setMenuOpen }}>
            {children}
        </MenuContext.Provider>
    )
}

/**
 * useMenuState
 */
const useMenuState = () => {
    const context = useContext(MenuContext)

    if (context === undefined) throw new Error('useMenuState must be used within a MenuProvider')

    return context
}

export { MenuProvider, useMenuState }

MenuProvider.propTypes = {
    children: PropTypes.element.isRequired,
}