import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { createPortal } from 'react-dom'
import cx from 'classnames';
import { usePortalState } from '@context';

import { gsap } from 'gsap';
import { CSSPlugin } from "gsap/CSSPlugin";

import './index.scss'

gsap.registerPlugin(CSSPlugin);

const portalRoot = typeof document !== `undefined` ? document.getElementById('portal') : null;

const Portal = forwardRef(({ children, className }, ref) => {
    const [isBrowser, setIsBrowser] = useState(false);
    /* eslint-disable no-unused-vars */
    const { show, setShow } = usePortalState();

    useEffect(() => {
        setIsBrowser(true)
    }, [])

    const portal = <div ref={ref} className={cx(`fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-80 portal`, className)}>{children}</div>

    if (isBrowser) {
        return createPortal(portal, portalRoot)
    } else {
        return null
    }
})

export default Portal;

