import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const LocaleContext = createContext()

/**
 * LocaleContext
 * 
 * @param {mixed} children
 */

const LocaleProvider = ({ children }) => {
    const [locale, setLocale] = useState('');

    return (
        <LocaleContext.Provider value={{ locale, setLocale }}>
            {children}
        </LocaleContext.Provider>
    )
}

/***
 * useLocaleState
 */
const useLocaleState = () => {
    const context = useContext(LocaleContext);

    if (context === undefined) throw new Error('useLocaleState menu be used within a LocaleProvider')

    return context;
}

export { LocaleProvider, useLocaleState }

LocaleProvider.propTypes = {
    children: PropTypes.element.isRequired
}