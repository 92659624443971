import React from "react";
import { Link as GatsbyLink } from "gatsby";
import PropTypes from "prop-types";
import cx from "classnames";

export default function Link({
  to,
  children,
  type = "link",
  external = false,
  clickHandler,
  onMouseEnter,
  onMouseLeave,
  target,
  className,
  spanClass,
  color = "primary",
  clipCircle = "default",
}) {
  const handleClick = (e) => {
    if (typeof clickHandler === "function") {
      clickHandler(e);
      return true;
    }
  };

  if (external) {
    return (
      <a
        {...{
          href: to,
          target: target ? target : external ? "_blank" : null,
          rel: "noreferrer noopenner",
          className: cx(
            "ru-countach-font overflow-hidden text-center group transition-all duration-500 relative flex items-center justify-center",
            className,
            {
              "px-10 py-3 decoration-none text-3xl uppercase tracking-wider font-display ru-countach-font bg-primary text-black group-hover:text-primary hover:text-primary":
                type === "button" && color === "primary",
              "px-10 py-3 decoration-none text-3xl uppercase tracking-wider font-display ru-countach-font bg-white text-primary group-hover:text-white hover:text-white":
                type === "button" && color === "black",
              "px-10 py-3 decoration-none text-3xl uppercase tracking-wider font-display ru-countach-font bg-black text-primary group-hover:text-black hover:text-black":
                type === "button" && color === "white",

              "no-underline": type === "link",
            }
          ),
          onClick: handleClick,
        }}
      >
        <span className={cx(`relative z-20`, spanClass)}>{children}</span>
        {type === "button" && (
          <div
            className={cx(
              `max-w-[90%] z-10 mx-auto group-hover:w-[275px] group-hover:h-[275px] absolute clip-circle ${`clip-circle--${clipCircle}`} rounded-full w-0 h-0 transition-all duration-500`,
              {
                "bg-black": color === "primary",
                "bg-primary": color === "white",
                "bg-primary": color === "black",
              }
            )}
          >
            <span
              className={`block clip-circle ${`clip-circle--${clipCircle}`} w-full h-full rounded-full z-10`}
            />
          </div>
        )}
      </a>
    );
  } else {
    return (
      <GatsbyLink
        {...{
          to,
          className: cx(
            "ru-countach-font overflow-hidden text-center group transition-all duration-500 relative flex items-center justify-center",
            className,
            {
              "px-10 py-3 decoration-none text-3xl uppercase tracking-wider font-display ru-countach-font bg-primary text-black group-hover:text-primary hover:text-primary":
                type === "button" && color === "primary",
              "px-10 py-3 decoration-none text-3xl uppercase tracking-wider font-display ru-countach-font bg-black text-primary group-hover:bg-primary hover:bg-primary group-hover:text-black hover:text-black":
                type === "button" && color === "black",
              "px-10 py-3 decoration-none text-3xl uppercase tracking-wider font-display ru-countach-font bg-black text-primary group-hover:text-black hover:text-black":
                type === "button" && color === "white",
              "no-underline": type === "link",
            }
          ),
          onMouseEnter,
          onMouseLeave,
          onClick: handleClick,
        }}
      >
        <span className={cx(`relative z-20`, spanClass)}>{children}</span>
        {type === "button" && (
          <div
            className={cx(
              `max-w-[90%] z-10 mx-auto group-hover:w-[275px] group-hover:h-[275px] absolute clip-circle rounded-full w-0 h-0 transition-all duration-500`,
              {
                "bg-black": color === "primary",
                "bg-primary": color === "white",
                "!bg-primary": color === "black",
              }
            )}
          >
            <span
              className={`block clip-circle w-full h-full rounded-full z-10`}
            />
          </div>
        )}
      </GatsbyLink>
    );
  }
}

Link.propTypes = {
  to: PropTypes.string.isRequired,
  external: PropTypes.bool,
  type: PropTypes.string,
  target: PropTypes.string,
  clickHandler: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  className: PropTypes.string,
  color: PropTypes.string,
};

Link.defaultProps = {
  external: false,
  type: "link",
};
