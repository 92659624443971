import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const LogoContext = createContext()

/**
 * MenuProvider
 * 
 * @param {mixed} children
 */
const LogoProvider = ({ children }) => {
    const [logoPosition, setLogoPosition] = useState(false)

    return (
        <LogoContext.Provider value={{ logoPosition, setLogoPosition }}>
            {children}
        </LogoContext.Provider>
    )
}

/**
 * uselogoState
 */
const useLogoState = () => {
    const context = useContext(LogoContext)

    if (context === undefined) throw new Error('useLogoState must be used within a LogoProvider')

    return context
}

export { LogoProvider, useLogoState }

LogoProvider.propTypes = {
    children: PropTypes.element.isRequired,
}