import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import LinkWithIcon from "@components/LinkWithIcon";

export default function SocialIcons({ icons, className, sizes = "w-8 h-8" }) {
  return (
    <div
      {...{
        className: cx(`text-offwhite`, className),
      }}
    >
      {icons.map((icon, _index) => (
        <LinkWithIcon
          {...{
            key: _index,
            icon: icon.name,
            to: icon.socialHomepageStoreFront,
            external: true,
            className: cx(
              `hover:text-primary fill-current cursor-pointer`,
              sizes
            ),
          }}
        />
      ))}
    </div>
  );
}

SocialIcons.propTypes = {
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      socialHomepageStoreFront: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  className: PropTypes.string,
};
