import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const PortalContext = createContext();

/**
 * PortalContext
 * 
 * @param {mixed} children
 * 
 * 
 */

const PortalProvider = ({ children }) => {
    const [show, setShow] = useState(false);

    return (
        <PortalContext.Provider value={{ show, setShow }}>
            {children}
        </PortalContext.Provider>
    )
}

/***
 * 
 * usePortalState
 */

const usePortalState = () => {
    const context = useContext(PortalContext);

    if (context === undefined) throw new Error(`usePortalState must be used within a PortalProvider`);

    return context;
}

export { PortalProvider, usePortalState }

PortalProvider.propTypes = {
    children: PropTypes.element.isRequired,
}

