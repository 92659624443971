import React, { useState } from "react";
import Cookies from "js-cookie";

import Button from "@components/Button";
import Toggle from "@components/Inputs/Toggle";
import Link from "@components/Link";
import cx from "classnames";

import "./styles.scss";

const CookieSettings = ({
  handleAccept,
  handleDecline,
  initTracking,
  handleCloseAll,
}) => {
  const [trackAnalyticsAndTargeting, setTrackAnalyticsAndTargeting] = useState(
    Cookies.get("gatsby-gdpr-google-analytics") === "true" &&
      Cookies.get("gatsby-gdpr-google-tagmanager")
  );

  const [trackAdvertisement, setTrackAdvertisement] = useState(
    Cookies.get("gatsby-gdpr-facebook-pixel") === "true"
  );

  const handleToggleAnalyticsAndTargeting = () => {
    //@ts-ignore
    Cookies.set("gatsby-gdpr-responded", true, { expires: 365 });
    setTrackAnalyticsAndTargeting((prevState) => {
      if (prevState) {
        Cookies.remove("gatsby-gdpr-google-analytics");
        Cookies.remove("gatsby-gdpr-google-tagmanager");
      } else {
        //@ts-ignore
        Cookies.set("gatsby-gdpr-google-analytics", true, {
          expires: 365,
        });
        Cookies.set("gatsby-gdpr-google-tagmanager", true, {
          expires: 365,
        });
      }

      return !prevState;
    });

    initTracking();
  };
  const handleToggleAdvertisement = () => {
    Cookies.set("gatsby-gdpr-responded", true, { expires: 365 });

    setTrackAdvertisement((prevState) => {
      if (prevState) {
        Cookies.remove("gatsby-gdpr-facebook-pixel");
      } else {
        Cookies.set("gatsby-gdpr-facebook-pixel", true, {
          expires: 365,
        });
      }

      return !prevState;
    });

    initTracking();
  };

  return (
    <div className="cookie-settings w-full sm:w-96 h-full p-8 fixed top-0 left-0 bg-white shadow-2xl overflow-y-scroll z-50">
      <h3 className="mb-4 text-2xl lg:text-3xl font-display ru-countach-font uppercase">
        This site uses cookies to store information on your computer.
      </h3>
      <div className="mb-4 text-sm">
        <p>
          Some of these cookies are essential, while others help us to improve
          your experience by providing insights into how the site is being used.
        </p>
        <p className="mt-4">
          You can find out how to manage your preferences in relation to our use
          of cookies in our{" "}
          <a
            {...{
              href: "/cookie-policy",
              className: "text-left underline text-primary",
            }}
          >
            Cookie Policy
          </a>
        </p>
      </div>
      <div className="flex items-center">
        <Button color="primary" clickHandler={() => handleAccept()}>
          Accept All
        </Button>
        <Button
          color="white"
          clickHandler={() => handleDecline()}
          className="ml-4"
        >
          Decline
        </Button>
      </div>
      <div className="pt-8 mt-8 border-t border-dashed border-primary">
        <div className="mb-4 flex items-center justify-between">
          <h4 className="text-xl lg:text-2xl font-display ru-countach-font uppercase">
            Necessary Cookies
          </h4>
        </div>
        <p className="text-sm">
          Necessary cookies enable core functionality such as page navigation
          and access to secure areas. The website cannot function properly
          without these cookies, and can only be disabled by changing your
          browser preferences.
        </p>
      </div>
      <div className="pt-8 mt-8 border-t border-dashed border-primary">
        <div className="mb-4 flex items-center justify-between">
          <h4 className="text-xl lg:text-2xl font-display ru-countach-font uppercase">
            Analytical & Targeting Cookies
          </h4>
          <Toggle
            active={trackAnalyticsAndTargeting}
            toggleActive={() => handleToggleAnalyticsAndTargeting()}
          />
        </div>
        <p className="text-sm">
          Analytical cookies are non-essential cookies used to understand how
          visitors interact with the website. These cookies help provide
          information on metrics such as the number of visitors, bounce rate,
          traffic source, etc which help us to improve our website.
        </p>
        <p className="text-sm">
          Targeting cookies are non-essential cookies used to provide visitors
          with customised advertisements based on the pages you visited
          previously and to analyse the effectiveness of the ad campaigns. The
          information we collect is anonymised.
        </p>
      </div>
      <div className="pt-8 mt-8 border-t border-dashed border-primary">
        <div className="mb-4 flex items-center justify-between">
          <h4 className="text-xl lg:text-2xl font-display ru-countach-font uppercase">
            Advertising Cookies
          </h4>
          <Toggle
            active={trackAdvertisement}
            toggleActive={() => handleToggleAdvertisement()}
          />
        </div>
        <p className="text-sm">
          Advertising cookies are non-essential cookies used to track visitors
          across websites. The intention is to display ads that are relevant and
          engaging for the individual user and thereby more valuable for
          publishers and third party advertisers.
        </p>
      </div>
      <Button
        color="white"
        clickHandler={() => handleCloseAll()}
        className="mt-8 md:mt-12 mx-auto"
      >
        Save
      </Button>
    </div>
  );
};

export default CookieSettings;
