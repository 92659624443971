import React from "react";
import cx from "classnames";

import Link from "@components/Link";

export default function FooterLink({
  to,
  children,
  target,
  external,
  className,
}) {
  return (
    <li className="group">
      <Link
        {...{
          target,
          to,
          external,
          className: cx(
            "text-offwhite uppercase font-display ru-countach-font text-center lg:!text-left text-3xl !block tracking-wide transition-all hover:text-primary lg:text-2xl",
            className
          ),
        }}
      >
        {children}
      </Link>
    </li>
  );
}
