import Portal from "@hoc/Portal";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";

import Title from "@components/Title";
import { AiOutlineClose } from "react-icons/ai";

import { useWishlistState } from "@context";
import PlatformItem from "./PlatformItem";
import React from "react";

export default function WishlistModal({ locale = "en" }) {
  const { configs } = useStaticQuery(graphql`
    query WishlistQuery {
      configs: allDatoCmsAnglerGlobalSetting {
        nodes {
          id
          locale
          buyNowHeaderText
          modalBuyNowText
          platforms {
            id
            platformName
            platformGameUrl
            platformIcon {
              url
              alt
            }
            platformBrandColour {
              hex
            }
          }
          buyNowModalBackground {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
        }
      }
    }
  `);

  const { show, setShow } = useWishlistState();

  const config = configs?.nodes?.find(
    ({ locale: _locale }) => _locale === locale
  );

  const background = getImage(config?.buyNowModalBackground.gatsbyImageData);
  const modalBg = convertToBgImage(background);

  return (
    show && (
      <Portal>
        <div className="max-w-[550px] w-11/12 bg-black text-white shadow-md">
          <div className="py-2 px-4 bg-light text-black flex justify-between items-center">
            <Title
              {...{
                type: 1,
                className: "text-xl md:text-2xl lg:text-3xl xl:text-3xl",
              }}
            >
              {config?.modalBuyNowText}
            </Title>

            <span
              className="p-2 bg-black cursor-pointer"
              onClick={() => setShow(false)}
            >
              <AiOutlineClose className="text-white text-3xl" />
            </span>
          </div>
          <BackgroundImage
            {...modalBg}
            className="px-4 py-16 md:px-16 relative bg-light"
          >
            <div className="flex flex-wrap justify-center items-center gap-10">
              {config &&
                config.platforms &&
                config.platforms.map((platform, i) => {
                  return <PlatformItem platform={platform} />;
                })}
            </div>
            {/* <BuyNowDropdown
              {...{
                platforms: config?.platforms,
                platformLabel: "Select Platform",
                editionLabel: "Select Edition",
                buttonText: config?.buyNowHeaderText,
              }}
            /> */}
          </BackgroundImage>
        </div>
      </Portal>
    )
  );
}
