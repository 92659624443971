import React, { useEffect } from 'react';
import { useMenuState } from '@context';

export default function Hamburger() {

    const { menuOpen, setMenuOpen } = useMenuState(false)

    useEffect(() => {

    }, [menuOpen])

    return (
        <div className="flex items-center lg:hidden justify-center z-50 gap-1 flex-col group cursor-pointer" onClick={() => setMenuOpen(!menuOpen)}>
            <span className={`w-8 block group-hover:bg-primary rounded h-[3px] bg-white`} />
            <span className={`w-5 block group-hover:bg-primary  rounded h-[3px] bg-white`} />
            <span className={`w-8 block group-hover:bg-primary rounded h-[3px] bg-white`} />
        </div>
    )
}
