import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

export default function Button({
  children,
  color = "black",
  type = "button",
  clickHandler,
  className,
}) {
  return (
    <div
      className={cx(
        `flex items-center cursor-pointer justify-center relative group overflow-hidden text-xl lg:text-2xl`,
        className,
        {
          "bg-primary text-black group-hover:text-primary hover:text-primary":
            color === "primary",
          "bg-white text-primary group-hover:text-white hover:text-white":
            color === "white",
          "bg-black text-primary group-hover:text-black hover:text-black":
            color === "black",
        }
      )}
    >
      <button
        {...{
          type,
          onClick: clickHandler,
          className: cx(
            `px-10 py-3 uppercase relative tracking-wider font-display ru-countach-font whitespace-nowrap transition-all z-20`
          ),
        }}
      >
        {children}
      </button>
      <div
        className={cx(
          `max-w-[90%] mx-auto group-hover:w-[275px] group-hover:h-[275px] absolute clip-circle rounded-full w-0 h-0 transition-all duration-500`,
          {
            "bg-black": color === "primary",
            "bg-primary": color === "black" || color === "white",
          }
        )}
      >
        <span className={`block clip-circle w-full h-full rounded-full z-10`} />
      </div>
    </div>
  );
}

Button.propTypes = {
  type: PropTypes.string.isRequired,
  clickHandler: PropTypes.func,
  color: PropTypes.string,
  className: PropTypes.string,
};

Button.defaultProps = {
  color: "black",
  type: "button",
  textColor: "white",
};
