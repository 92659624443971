import React, { useState } from "react";
import cx from "classnames";
import Icon from "@components/Icon";

const PlatformItem = ({ platform }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <a
      className={cx(
        `cursor-pointer max-w-[110px] min-w-[110px] min-h-[62px] flex justify-center items-center border-offwhite bg-light hover:scale-110 hover:shadow-md border px-3 py-3 group duration-300 transition-all`
      )}
      href={platform.platformGameUrl}
      target="_blank"
      rel="noreferrer noopenner"
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      style={{
        border: isHovering
          ? `1px solid ${platform.platformBrandColour.hex}`
          : "1px solid #666666",
        backgroundColor: isHovering
          ? platform.platformBrandColour.hex
          : "#f4f4f4",
      }}
    >
      <Icon
        src={platform.platformIcon?.url}
        className="fill-offwhite group-hover:fill-white duration-300 transition-all h-auto"
      />
    </a>
  );
};

export default PlatformItem;
