import React from "react";
import { useMenuState } from "@context";
import NavLink from "@components/Header/NavLink";

import getModelLink from "@utils/getModelLink";

export default function Sidedrawer({ links, locale, apexButtonText }) {
  const { menuOpen } = useMenuState();

  return (
    <div
      className={`fixed w-full z-40 h-full bg-black top-0 block py-10 transition-all duration-500 ${
        menuOpen ? "left-[0%]" : "left-[100%]"
      } text-white`}
    >
      <nav className="flex items-center justify-center flex-col text-center h-full w-full">
        <ul>
          {links?.map((link) => (
            <NavLink
              {...{
                key: link.id,
                to: getModelLink(link.pageSlug, locale),
                className:
                  "uppercase font-display ru-countach-font text-5xl tracking-wide",
              }}
            >
              {link.pageName}
            </NavLink>
          ))}
          {apexButtonText && (
            <NavLink
              {...{
                target: "_blank",
                to: "https://apex-connect.avalanchestudios.com/?theme=trt",
                external: true,
                className:
                  "uppercase font-display ru-countach-font text-5xl tracking-wide",
              }}
            >
              {apexButtonText}
            </NavLink>
          )}
        </ul>
      </nav>
    </div>
  );
}
