import React from "react";
import PropTypes from "prop-types";
import NavLink from "@components/Header/NavLink";

import getModelLink from "@utils/getModelLink";

export default function Nav({ links, locale }) {
  return (
    <nav className="text-white hidden lg:block lg:col-span-5">
      <ul className="flex flex-row flex-nowrap gap-6 uppercase list-none p-0 m-0">
        {links &&
          links.map((link) => (
            <NavLink
              {...{
                key: link.id,
                to: getModelLink(link.pageSlug, locale),
                className:
                  "uppercase font-display ru-countach-font text-lg xl:text-xl tracking-wide ru-countach-font",
              }}
            >
              {link.pageName}
            </NavLink>
          ))}
      </ul>
    </nav>
  );
}

Nav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
};
