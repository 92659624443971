import React from 'react';
import cx from 'classnames'
import PropTypes from 'prop-types'
import './styles.scss';

export default function Toggle({
    active = false,
    toggleActive,
}) {

    return (
        <div onClick={typeof toggleActive === 'function' && toggleActive} className={cx('toggle relative cursor-pointer', { active })} />
    )
}

Toggle.propTypes = {
    active: PropTypes.bool,
    toggleActive: PropTypes.func.isRequired
}

Toggle.defaultProps = {
    active: false,
}