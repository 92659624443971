import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types'

const LightboxContent = createContext();

/**
 * LightboxProvider
 * 
 * @param {mixed} children
 */

const LightboxProvider = ({ children }) => {
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [activeLightboxImage, setActiveLightboxImage] = useState(0);
    const [lightboxImages, setLightboxImages] = useState([]);
    const [lightboxVideo, setLightboxVideo] = useState(false);

    return (
        <LightboxContent.Provider
            value={{
                lightboxOpen, setLightboxOpen,
                activeLightboxImage, setActiveLightboxImage,
                lightboxImages, setLightboxImages,
                lightboxVideo, setLightboxVideo
            }}
        >
            {children}
        </LightboxContent.Provider>
    )
}

const useLightboxState = () => {
    const context = useContext(LightboxContent);

    if (context === undefined) throw new Error('useLightboxState must be used within a LightboxProvider')

    return context
}

export { LightboxProvider, useLightboxState }

LightboxProvider.propTypes = {
    children: PropTypes.element.isRequired,
}