import Link from "@components/Link";
import React from "react";
import cx from "classnames";

export default function NavLink({ to, children, target, external, className }) {
  return (
    <li className="relative py-6 group list-none">
      <Link
        {...{
          target,
          to,
          external,
          className: cx(
            "text-white uppercase font-display ru-countach-font text-xl xl:text-2xl tracking-wide transition-all group-hover:text-offwhite",
            className
          ),
        }}
      >
        {children}
      </Link>

      <div className="absolute group-hover:block hidden bottom-0 left-0 w-full h-2 bg-primary transition-all" />
    </li>
  );
}
