import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function Paragraph({
    className,
    children,
    color = 'offwhite',
    lineheight = '',
}) {

    return (
        <p {...{
            className: cx(`leading-6 lg:leading-8 text-sm md:text-md font-body tracking-wide`, {
                'text-offwhite': color === 'offwhite',
                'text-white': color === 'white'
            }, className)
        }}>{children}</p>
    )
}

Paragraph.propTypes = {
    className: PropTypes.string,
    children: PropTypes.string.isRequired,
    color: PropTypes.string,
    lineheight: PropTypes.string
}

Paragraph.defaultProps = {
    color: 'offwhite',
    size: 'md',
}