import { ContextProvider } from '@context';
import React from 'react';
import Header from '@components/Header';
import Footer from '@components/Footer';
import CookieBanner from '@components/CookieBanner';
import WishlistModal from '@components/WishlistModal';

export default function Layout({ children, locale }) {

    return (
        <ContextProvider>
            {locale ? (
                <>
                    <Header locale={locale} />
                    <main className='relative pt-12 lg:pt-16 overflow-x-hidden'>
                        {children}
                    </main>
                    <CookieBanner />
                    <WishlistModal locale={locale} />
                    <Footer locale={locale} />
                </>
            ) : (
                <>
                    <Header />
                    <main className='relative pt-12 lg:pt-16 overflow-x-hidden'>
                        {children}
                    </main>
                    <CookieBanner />
                    <WishlistModal />
                    <Footer />
                </>
            )}
        </ContextProvider>
    )
}

