export default function getModelLink(slug, _locale, type = 'page') {
    const defaultLang = 'en'
    const locale = _locale ? _locale.toLowerCase() : defaultLang
    const _type = type.toLowerCase()

    switch (_type) {
        case 'page':
        case 'angler page':
            if (slug === 'home') {
                return locale === defaultLang ? `/` : `/${locale}`
            } else {
                return locale === defaultLang ? `/${slug}` : `/${locale}/${slug}`
            }
        case 'news':
            return locale === defaultLang
                ? `/news/${slug}`
                : `/${locale}/news/${slug}`
        case 'guide':
        case 'guides':
            return locale === defaultLang
                ? `/guides/${slug}`
                : `/${locale}/guides/${slug}`
        case 'creator':
            return locale === defaultLang
                ? `/creators/${slug}`
                : `/${locale}/creators/${slug}`
        default:
            return locale === defaultLang ? `/${slug}` : `/${locale}/${slug}`
    }
}
