import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { AiFillCaretDown } from "react-icons/ai";
import { useLocaleState } from "@context";
import Link from "@components/Link";
import Icon from "@components/Icon";

export default function LocaleSwitcher() {
  const {
    datoCmsSite: { locales },
  } = useStaticQuery(graphql`
    query LocaleQuery {
      datoCmsSite {
        locales
      }
    }
  `);

  /* eslint-disable no-unused-vars */
  const { locale, setLocale } = useLocaleState();

  const [open, setOpen] = useState(false);

  const handleLocale = (_locale) => {
    setLocale(_locale);
  };

  return (
    <div className="relative">
      <div
        className="flex flex-row flex-nowrap items-center justify-center gap-2"
        onClick={() => setOpen(!open)}
      >
        <Icon name="locale" className="max-w-[25px] max-h-[25px]" />
        <AiFillCaretDown
          className={`${
            open ? "rotate-180" : "rotate-0"
          } text-white transform transition-transform`}
        />
      </div>

      <div
        className={`${
          open
            ? "translate-y-0 opacity-100"
            : "-translate-y-[120%] opacity-0 pointer-events-none"
        } absolute bg-black top-[250%] -left-4 p-4 text-offwhite transform transition-all duration-300 flex flex-col gap-3`}
      >
        {locales.map((locale, index) => (
          <Link
            key={index}
            to={`${locale === "en" ? "/" : `/${locale.toLowerCase()}`}`}
            key={index}
            className="uppercase font-display ru-countach-font text-2xl text-white tracking-wide hover:text-primary transition-colors whitespace-nowrap"
            clickHandler={() => handleLocale(locale)}
          >
            {locale.toLowerCase()}
          </Link>
        ))}
      </div>
    </div>
  );
}
