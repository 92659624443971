import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import PropTypes from "prop-types";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import Link from "@components/Link";
import Nav from "@components/Header/Nav";
import NavLink from "@components/Header/NavLink";
import Hamburger from "@components/Header/Hamburger";
import Sidedrawer from "@components/Header/Sidedrawer";
import Button from "@components/Button";
import { useLogoState, useWishlistState, useTwitchState } from "@context";
import LocaleSwitcher from "@components/LocaleSwitcher";
import Icon from "@components/Icon";

export default function Header({ locale }) {
  /* eslint-disable no-unused-vars */
  const { show, setShow } = useWishlistState();
  const { logoPosition, setLogoPosition } = useLogoState();
  const { online } = useTwitchState();

  const { configs } = useStaticQuery(graphql`
    query StaticHeaderQuery {
      configs: allDatoCmsAnglerGlobalSetting {
        nodes {
          id
          locale
          headerLogo {
            gatsbyImageData(placeholder: BLURRED)
            alt
          }
          buyNowHeaderText
          modalBuyNowText
          apexButtonText
          navigationLinks {
            id
            locale
            pageSlug
            pageName
          }
          rightNavigationLinks {
            id
            locale
            pageSlug
            pageName
          }
          headerButtonText
        }
      }
    }
  `);

  const config = configs?.nodes?.find(
    ({ locale: _locale }) => _locale === locale
  );

  const headerLogo = getImage(config?.headerLogo?.gatsbyImageData);

  return (
    <>
      <header className="bg-black px-4 flex items-center justify-between fixed top-0 left-0 w-full z-[9999] py-4 lg:py-0 lg:grid lg:grid-cols-12">
        <Nav
          {...{
            links: config.navigationLinks,
            locale: config.locale,
          }}
        />

        <div
          className={`lg:col-span-2 lg:flex lg:items-center lg:justify-center ${
            logoPosition ? "opacity-0" : "opacity-100"
          } transition-opacity`}
        >
          <Link
            {...{
              to: config.locale === "en" ? "/" : `/${config.locale}`,
              className: "",
            }}
          >
            <GatsbyImage
              image={headerLogo}
              alt={config?.headerLogo?.alt}
              className="mx-auto max-w-[175px]"
            />
          </Link>
        </div>

        <div className="flex-row flex-nowrap items-center justify-center gap-6 hidden lg:flex lg:col-span-5 lg:justify-end">
          <Nav
            {...{
              links: config.rightNavigationLinks,
              locale: config.locale,
            }}
          />
          <NavLink
            {...{
              target: "_blank",
              to: "https://apex-connect.avalanchestudios.com/?theme=trt",
              external: true,
              className:
                "text-white uppercase font-display ru-countach-font text-lg xl:text-xl tracking-wide hover:text-white whitespace-nowrap",
            }}
          >
            {config?.apexButtonText}
          </NavLink>
          <LocaleSwitcher />
          <div
            className={`flex items-center cursor-pointer justify-center relative group overflow-hidden text-lg xl:text-xl bg-primary text-black hover:text-primary`}
          >
            <button
              type="button"
              onClick={() => setShow(true)}
              className="px-6 xl:px-10 py-3 uppercase relative tracking-wider font-display ru-countach-font whitespace-nowrap transition-all z-20"
            >
              {config?.headerButtonText}
            </button>
            <div
              className={`max-w-[90%] mx-auto group-hover:w-[275px] group-hover:h-[275px] absolute clip-circle rounded-full w-0 h-0 transition-all duration-500 bg-black`}
            >
              <span
                className={`block clip-circle w-full h-full rounded-full z-10`}
              />
            </div>
          </div>
          {/* <Button
            {...{
              external: true,
              color: "primary",
              clickHandler: () => setShow(true),
              className: "text-xl xl:text-2xl min-w-fit",
            }}
          >
            {config?.headerButtonText}
          </Button> */}
        </div>
        <div className="flex">
          <div className="block lg:hidden mr-6">
            <LocaleSwitcher />
          </div>
          <Hamburger />
        </div>
        <Sidedrawer
          {...{
            links: [...config.navigationLinks, ...config.rightNavigationLinks],
            locale: config.locale,
            apexButtonText: config?.apexButtonText,
          }}
        />
      </header>
      <a
        href="https://www.twitch.tv/expansiveworlds"
        target="_blank"
        rel="noreferrer noopenner nofollow"
        className={`twitch-online fixed flex items-center justify-center py-4 h-16 mt-0.5 lg:-mt-2 xl:mt-0 px-28 top-16 lg:top-20 left-1/2 transform -translate-x-1/2 ${
          online ? "opacity-100 visible" : "opacity-0 invisible"
        } pointer-events-nonee select-none font-display ru-countach-font font-medium text-xl md:text-2xl text-black text-center leading-3 uppercase z-[9998] whitespace-nowrap`}
      >
        <span className="inline-block mr-1 text-white">LIVE</span>{" "}
        <span className="inline-block w-3 h-3 mx-2 rounded-full bg-youtube glow-live" />{" "}
        WATCH NOW ON{" "}
        <Icon
          name="twitchLogo"
          className="ml-1.5 max-w-[66px] -mt-[2px] leading-0 fill-white"
        />
      </a>
    </>
  );
}

Header.propTypes = {
  locale: PropTypes.string.isRequired,
};
