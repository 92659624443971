import React from 'react';
import PropTypes from 'prop-types'
import Svg from 'react-inlinesvg'

import svgs from './svgs';

export default function Icon({
    name,
    src,
    className
}) {
    return (name) ? (
        <Svg src={svgs[name]} {...{ className: className }} />
    ) : (
        <Svg src={src} {...{ className: className }} />
    )

}

Icon.propTypes = {
    name: PropTypes.string,
    src: PropTypes.string,
    className: PropTypes.string,
}