import discord from './discord.svg'
import facebook from './facebook.svg'
import instagram from './instagram.svg'
import steam from './steam.svg'
import twitter from './twitter.svg'
import youtube from './youtube.svg'
import arrowRight from './arrowRight.svg'
import angleDown from './angleDown.svg'
import locale from './locale.svg'

import play from './play.svg'
import cross from './cross.svg'
import plus from './plus.svg'

import playstation5logo from './playstation-5-logo.svg'
import xboxonelogo from './xbox-one-logo.svg'
import steamlogo from './steam-logo.svg'
import twitch from './twitch.svg'
import twitchLogo from './twitch-logo.svg'
import reddit from './reddit.svg'
import linkedin from './linkedin.svg'
import tiktok from './tiktok.svg'
import download from './download.svg'
import copy from './copy.svg'
import arrowDown from './arrow-down.svg'

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    discord,
    facebook,
    instagram,
    steam,
    twitter,
    linkedin,
    youtube,
    arrowRight,
    playstation5logo,
    xboxonelogo,
    steamlogo,
    tiktok,
    play,
    cross,
    twitch,
    twitchLogo,
    reddit,
    plus,
    angleDown,
    download,
    copy,
    arrowDown,
    locale,
}
