import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import Paragraph from '@components/Paragraph';
import SocialIcons from '@components/SocialIcons';
import FooterLink from '@components/Footer/FooterLink';

import DashedLine from '@components/DashedLine';
import getModelLink from '@utils/getModelLink';


export default function Footer({ locale }) {
    const { footer } = useStaticQuery(graphql`
         query FooterQuery {
            footer: allDatoCmsAnglerGlobalSetting {
                nodes {
                    id
                    locale
                    headerLogo {
                        gatsbyImageData(placeholder: BLURRED)
                        alt
                    }
                    legalLine
                    footerPolicyLinks {
                        pageSlug
                        pageName
                    }
                    footerLinks {
                        ... on DatoCmsAnglerPage {
                            id
                            pageName
                            pageSlug
                        }
                        ... on DatoCmsAnglerPolicyPage {
                            id
                            pageName
                            pageSlug
                        }
                    }
                    licensingLogos {
                        gatsbyImageData(placeholder: BLURRED, width: 50)
                        alt
                    }
                    socialIcons {
                        id
                        name
                        socialHomepageStoreFront

                    }
                }
            }
        }
    `);

    const config = footer?.nodes?.find(
        ({ locale: _locale }) => _locale === locale
    );

    const footerLogo = getImage(config?.headerLogo?.gatsbyImageData);

    return (
        <footer className="text-grey -mt-5 lg:-mt-8 xl:-mt-10 relative z-20">
            <div className="col-span-full relative flex items-end justify-end overflow-x-hidden -mb-0.5">
                <StaticImage src='../../assets/dividers/bkg-footer-top@2x.png' alt="Divider Graphic" />
            </div>
            <div className="py-16 bg-black relative">
                <div className='w-11/12 mx-auto flex items-center justify-center flex-col lg:gap-6 lg:flex-row max-w-md lg:max-w-screen-xl lg:justify-between'>
                    <div className="flex flex-col items-center justify-center gap-6 lg:items-start mb-10 lg:mb-0 lg:justify-between">
                        <GatsbyImage image={footerLogo} alt={config?.headerLogo?.alt ? config.headerLogo.alt : ``} className="max-w-[225px] mb-4 lg:mb-8" />
                        <Paragraph {...{
                            className: 'text-center lg:text-left'
                        }}>{config?.legalLine}</Paragraph>
                        {config?.footerPolicyLinks && (
                            <nav>
                                <ul className="flex flex-col items-center lg:flex-row gap-8 list-none">
                                    {config?.footerPolicyLinks.map(({ pageSlug, pageName }, _index) => <FooterLink {...{
                                        key: _index, to: getModelLink(pageSlug, locale, 'page')
                                    }}>{pageName}</FooterLink>)}
                                </ul>
                            </nav>
                        )}
                    </div>

                    <div className="flex gap-10 flex-col items-center justify-center lg:flex-row lg:gap-6 xl:gap-16">
                        <nav className="">
                            <ul className="flex items-center justify-center flex-row flex-wrap text-left gap-10 lg:max-w-[300px] lg:grid lg:grid-cols-2 lg:gap-y-4 lg:gap-x-16">
                                {config?.footerLinks.map(({ pageSlug, pageName }, _index) => (
                                    <FooterLink {...{
                                        key: _index, to: getModelLink(pageSlug, locale, 'page'),
                                    }}>{pageName}</FooterLink>
                                ))}
                            </ul>
                        </nav>

                        <DashedLine {...{
                            length: 220,
                            svgWidth: 220,
                            color: '#34adbd',
                            width: 2,
                            dasharray: '10, 10',
                            className: 'mx-auto lg:hidden',
                        }} />

                        <DashedLine {...{
                            length: 220,
                            color: '#34adbd',
                            width: 3,
                            svgWidth: '10',
                            dasharray: '10, 10',
                            className: 'mx-auto lg:block hidden',
                            isVertical: true,
                            svgPosition: {
                                x1: 5,
                                y1: 5,
                                x2: 5
                            }
                        }} />

                        <div className="flex flex-col gap-6">
                            <div className="flex flex-row gap-12 items-center justify-center mb-8">
                                {config?.licensingLogos.map((logo, index) => {
                                    const image = getImage(logo.gatsbyImageData);

                                    return <GatsbyImage key={index} image={image} alt={logo.alt ? logo.alt : ` `} />
                                })}
                            </div>


                            <SocialIcons {...{
                                icons: config?.socialIcons,
                                className: 'flex items-center justify-center flex-wrap text-center gap-x-10 gap-y-12 lg:gap-6 max-w-[250px] lg:max-w-none xl:flex-nowrap',
                                sizes: 'w-8 h-8 lg:w-6 lg:h-6'
                            }} />
                        </div>
                    </div>
                </div>
                <StaticImage src="../../assets/geolines/bkg-black-lines-left@2x.png" className="!absolute bottom-0 left-0 max-w-[550px]" alt="Geolines Graphic" />
            </div>
        </footer>
    )
}